import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useEffect,
} from 'react'
import { Loading } from '~/components'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps, navigate } from 'gatsby'
import { useCookies } from 'react-cookie'

interface Profile {
  name: string
  emails: string
  document: string
  company: string
}

interface Context {
  token: string
  setToken: Dispatch<SetStateAction<string>>
  profile: Profile
  setProfile: Dispatch<SetStateAction<Profile>>
  setLoading: Dispatch<SetStateAction<boolean>>
  loginReturn: string
  setLoginReturn: Dispatch<SetStateAction<string>>
  logout: () => void
}

export const context = createContext<Context>(null)

const layout = (loadingDefault: boolean, { children, location }: PageProps) => {
  const [loading, setLoading] = useState(loadingDefault)
  const [loadingActive, setLoadingActive] = useState(loadingDefault)
  const [enterOpacity, setEnterOpacity] = useState(0.9999)
  const [cookies, setCookie, removeCookie] = useCookies()
  const [token, _setToken] = useState<string>(cookies.token)
  const setToken = (token: string) => {
    _setToken(token)
    if (token) setCookie('token', token, { path: '/' })
    else removeCookie('token', { path: '/' })
  }
  const [profile, _setProfile] = useState<Profile>(cookies.profile)
  const setProfile = (profile: Profile) => {
    _setProfile(profile)
    if (profile) setCookie('profile', profile, { path: '/' })
    else removeCookie('profile', { path: '/' })
  }
  const [loginReturn, setLoginReturn] = useState<string>()

  useEffect(() => {
    setEnterOpacity(0)
  }, [])

  return (
    <>
      {loading && <Loading active={loadingActive} />}
      <context.Provider
        value={{
          token,
          setToken,
          profile,
          setProfile,
          setLoading: (value: boolean) => {
            if (value) {
              setLoading(true)
              setLoadingActive(true)
            } else {
              setLoadingActive(false)
              setTimeout(() => setLoading(false), 500)
            }
          },
          loginReturn,
          setLoginReturn,
          logout: () => {
            setToken(null)
            setProfile(null)
            navigate('/')
          },
        }}
      >
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: enterOpacity,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
      </context.Provider>
    </>
  )
}

export default (props: PageProps) => {
  const context = props.pageContext as { [key: string]: never }
  if (context?.layout === '404') return layout(true, props)
  return layout(false, props)
}
