import React from 'react'
import * as st from '~/assets/styl/SelectList.module.styl'

interface Props {
  total: string
  itemsQuantity: number
  setItemsQuantity: React.Dispatch<React.SetStateAction<number>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const SelectList = ({
  total,
  itemsQuantity,
  setItemsQuantity,
  setPage,
}: Props) => {
  const selectNumbers = Array.from({ length: 10 }, (_, i) => (i + 1) * 10)
  const handleSelect = (item) => {
    setPage(1)
    setItemsQuantity(parseInt(item.target.value))
  }

  return (
    <p className={st.core}>
      Listar{' '}
      <strong>
        <div>
          <select value={itemsQuantity} onChange={(e) => handleSelect(e)}>
            <option value="" disabled hidden selected>
              {itemsQuantity.toString()}
            </option>
            {selectNumbers.map((item, index) => (
              <option key={index} value={item}>
                {item.toString()}
              </option>
            ))}
          </select>
        </div>
      </strong>{' '}
      de {total.toString()}{' '}
      {location.pathname == '/notas-fiscais/' ? 'notas fiscais' : 'boletos'}
    </p>
  )
}

export default SelectList
