import { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useContext } from '~/hooks'
import api from '.'

const useApi = (defaultValue, path) => {
  const [value, setValue] = useState(defaultValue)

  const { setLoading } = useContext()

  useEffect(() => {
    if (!path) return
    setLoading(true)
    api('get', path)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(({ code, message, ...data }) => {
        setValue(data)
        setLoading(false)
      })
      .catch(() => navigate('/404', { state: { logout: true } }))
  }, [path])

  return value
}

export default useApi
