import React, { useEffect, useState } from 'react'
import * as st from '~/assets/styl/Table.module.styl'
import cn from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Filter, ModalSlips, Pagination, SelectList } from '~/components'

const mapHead = {
  Emissão: 'dateIssuance',
  Documento: 'number',
  Série: 'serie',
  Valor: 'value',
  Prefixo: 'serie',
  Número: 'number',
  Parcela: 'installment',
  Vencimento: 'dateDue',
  'Em aberto': 'balance',
}

type Props = {
  dataHead: Array<string>
  dataCells: Array<{
    dateIssuance: string
    status?: string
    details: never[]
  }>
  dataNumberBillet?: Array<[]>
  hasDetails?: boolean
  max: number
  page: number
  pagesNum: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setMax: React.Dispatch<React.SetStateAction<number>>
  setInitialDate: React.Dispatch<React.SetStateAction<string>>
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
  setIsOpen?: React.Dispatch<React.SetStateAction<number>>
  isOpen?: Array<string>
  setNumberInvoice?: (numberInvoice: number) => void
  setNumberBillet?: (numberBillet: number) => void
  numberInvoice?: number
  numberBillet?: number
  setSerieInvoice?: (serieInvoice: string) => void
  serieInvoice?: string
  total: string
  message?: string
}

const Table = ({
  dataHead,
  dataCells,
  hasDetails,
  page,
  max: itemsQuantity,
  pagesNum,
  setPage,
  setMax: setItemsQuantity,
  setInitialDate,
  setFinalDate,
  setNumberInvoice,
  setNumberBillet,
  numberInvoice,
  numberBillet,
  dataNumberBillet,
  setSerieInvoice,
  serieInvoice,
  setIsOpen,
  isOpen,
  total,
  message,
}: Props) => {
  const [openDetails, setOpenDetails] = useState(-1)
  const [openOptions, setOpenOptions] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [status, setStatus] = useState('')

  const toggleDetails = (index) => {
    setOpenDetails(index === openDetails ? -1 : index)
    setOpenOptions('')
  }

  const [sorting, setSorting] = useState('')
  const [ascending, setAscending] = useState(1)

  const transform = [
    'number',
    'serie',
    'value',
    'installment',
    'balance',
  ].includes(sorting)
    ? (value: string) =>
        parseFloat(
          String(value)
            .replace(/[^0-9,]/g, '')
            .replace(/,/, '.')
        )
    : ['dateIssuance', 'dateDue'].includes(sorting)
    ? (value: string) => {
        const dateParts = value.split('/').map((i) => parseInt(i))
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
      }
    : (value: string) => value
  const quantityViewSorted = sorting
    ? [...dataCells].sort((a, b) => {
        return transform(a[sorting]) > transform(b[sorting])
          ? ascending
          : -ascending
      })
    : dataCells

  const toggleModal = (invoice) => {
    setSerieInvoice(invoice?.serie)
    setNumberInvoice(invoice?.numberNF)
    dataNumberBillet.forEach((element) => {
      if (element?.numberNF === invoice?.numberNF) {
        setNumberBillet(element?.number)
      }
    })
    setOpenModal(true)
    setOpenOptions('')
  }

  const handleOrder = (prop: string) => {
    setSorting(prop)
    if (prop === sorting) setAscending(-ascending)
    else setAscending(1)
  }

  const breakpoints = useBreakpoint()

  useEffect(() => {
    dataCells.forEach((data) => {
      setStatus(data.status)
    })
  }, [dataCells, quantityViewSorted])

  return (
    <>
      {dataCells.length > 5 && breakpoints.sm && (
        <SelectList
          total={total || dataCells.length.toString()}
          itemsQuantity={itemsQuantity}
          setItemsQuantity={setItemsQuantity}
        />
      )}
      <Filter
        setIsOpen={setIsOpen}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setPage={setPage}
        isOpen={isOpen}
      />
      <table className={cn(st.core, hasDetails && st.customSpace)}>
        <thead>
          <tr>
            {dataHead.map((item, index) => (
              <th key={index} onClick={() => handleOrder(mapHead[item])}>
                <span>{item}</span>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {quantityViewSorted.length === 0 ? (
            <tr>
              <td colSpan={12}>
                <p className={st.textNotContent}>
                  {message ? message : 'Nenhum registro encontrado!'}
                </p>
              </td>
            </tr>
          ) : hasDetails ? (
            quantityViewSorted.map(({ details, ...props }, index) => (
              <>
                <tr
                  key={index}
                  className={cn(st.icoPlus, openDetails === index && st.active)}
                >
                  {Object.values(props).map((value, key) =>
                    !value?.toString().startsWith('https') ? (
                      <td onClick={() => toggleDetails(index)} key={key}>
                        {value}
                      </td>
                    ) : (
                      <td className={st.btnOptions}>
                        <button
                          onClick={() =>
                            setOpenOptions(openOptions === '' ? `${index}` : '')
                          }
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </button>
                        <ul className={openOptions === `${index}` && st.active}>
                          {!value.endsWith('https') && (
                            <li>
                              <a
                                href={value as string}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Nota fiscal
                              </a>
                            </li>
                          )}
                          <li>
                            <button onClick={() => toggleModal(props)}>
                              Títulos
                            </button>
                          </li>
                        </ul>
                      </td>
                    )
                  )}
                </tr>
                <tr
                  className={cn(st.details, openDetails === index && st.active)}
                >
                  <td colSpan={12}>
                    <table>
                      <tr>
                        <th>Projeto</th>
                        <th>Item</th>
                        <th>Preço</th>
                        <th>Quantidade</th>
                        <th>Total</th>
                        <th>Imposto</th>
                        <th>%</th>
                      </tr>
                      {details?.map(
                        (
                          {
                            project,
                            item,
                            price,
                            quantity,
                            total,
                            tax,
                            percentage,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{project}</td>
                            <td>{item}</td>
                            <td>{price}</td>
                            <td>{quantity}</td>
                            <td>{total}</td>
                            <td>{tax}</td>
                            <td>{percentage}</td>
                          </tr>
                        )
                      )}
                    </table>
                  </td>
                </tr>
              </>
            ))
          ) : (
            quantityViewSorted.map(({ ...props }, index) => (
              <tr key={index} className={st.icoSlips}>
                {Object.entries(props).map(
                  ([prop, value], key) =>
                    prop.toString() != 'fileData' &&
                    (prop.toString() != 'link' ? (
                      <td
                        onClick={() => toggleDetails(index)}
                        key={key}
                        className={cn(value === 'Aberto' ? st.red : st.green)}
                      >
                        {value}
                      </td>
                    ) : (
                      <td className={st.btnOptions}>
                        {status === 'Aberto' && (
                          <a
                            className={st.icoPrint}
                            href={value as string}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Imprimir
                          </a>
                        )}
                      </td>
                    ))
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div>
        {!breakpoints.sm && dataCells.length > 0 && (
          <SelectList
            total={total || dataCells.length.toString()}
            itemsQuantity={itemsQuantity}
            setItemsQuantity={setItemsQuantity}
            setPage={setPage}
          />
        )}
        {dataCells.length > 0 && (
          <Pagination
            page={page}
            itemsPerPage={itemsQuantity}
            pageCount={pagesNum}
            setPage={setPage}
          />
        )}
      </div>
      {openModal && (
        <ModalSlips
          serieInvoice={serieInvoice}
          numberInvoice={numberInvoice}
          numberBillet={numberBillet}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  )
}

export default Table
