// extracted by mini-css-extract-plugin
export var active = "Table-module--active--87dbf";
export var btnOptions = "Table-module--btn-options--e2307";
export var core = "Table-module--core--19dae";
export var customPadding = "Table-module--custom-padding--f37eb";
export var customSpace = "Table-module--custom-space--f92a6";
export var details = "Table-module--details--f6f95";
export var green = "Table-module--green--d5414";
export var icoPlus = "Table-module--ico-plus--48249";
export var icoPrint = "Table-module--ico-print--a2f76";
export var icoSlips = "Table-module--ico-slips--7c552";
export var red = "Table-module--red--74c7b";
export var textNotContent = "Table-module--textNotContent--dc23f";