import React, { useState } from 'react'
import * as st from '~/assets/styl/Filter.module.styl'
import cn from 'classnames'

interface Props {
  setInitialDate: React.Dispatch<React.SetStateAction<string>>
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
  setIsOpen?: React.Dispatch<React.SetStateAction<number>>
  isOpen?: Array<string>
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const Filter = ({
  setInitialDate,
  setFinalDate,
  setIsOpen,
  isOpen,
  setPage,
}: Props) => {
  const [initialDate, _setInitialDate] = useState('')
  const [finalDate, _setFinalDate] = useState('')
  const [_isOpen, _setIsOpen] = useState(1)
  const [openFilterMobile, setOpenFilterMobile] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    setPage(1)
    if (isOpen) {
      setIsOpen(_isOpen)
    }
    setInitialDate(initialDate?.split('-').reverse().join('/') || '01/01/1980')
    setFinalDate(finalDate?.split('-').reverse().join('/') || '30/12/2100')
    setTimeout(
      () => setAllowClear(Boolean(initialDate || finalDate || _isOpen)),
      100
    )
  }

  const [allowClear, setAllowClear] = useState(false)
  const handleClear = (e) => {
    e.preventDefault()
    _setInitialDate('')
    setPage(1)
    _setIsOpen(1)
    setIsOpen(1)
    setInitialDate('')
    _setFinalDate('')
    setFinalDate('')
    setTimeout(() => setAllowClear(false), 100)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={cn(st.core, openFilterMobile && st.active)}
      >
        {openFilterMobile && (
          <div className={st.headerMobile}>
            <button onClick={() => setOpenFilterMobile(false)}>Filtrar</button>
          </div>
        )}
        <div className={st.customWidth}>
          <label htmlFor="data-inicial">Emissão de</label>
          <input
            type="date"
            id="data-inicial"
            name="data-inicial"
            value={initialDate}
            onChange={(e) => _setInitialDate(e.target.value)}
          />
        </div>
        <div className={st.customWidth}>
          <label htmlFor="data-final">Emissão até</label>
          <input
            type="date"
            id="data-final"
            name="data-final"
            value={finalDate}
            onChange={(e) => _setFinalDate(e.target.value)}
          />
        </div>
        <div>
          {location.pathname.toString().startsWith('/boletos') ? (
            <>
              <label htmlFor="aberto">Em aberto</label>
              <div className={st.select}>
                <select
                  name="aberto"
                  value={_isOpen}
                  onChange={(e) => _setIsOpen(Number(e.target.value))}
                >
                  {(isOpen || []).map((status, index) => (
                    <option
                      value={status == 'Aberto' ? 1 : 2}
                      key={index}
                      selected={Number(_isOpen) === 1 ?? selected}
                    >
                      {status == 'Aberto' ? 'Sim' : 'Não'}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : null}
        </div>
        <button className="btn blue" type="submit">
          {!openFilterMobile ? 'Filtrar' : 'Aplicar filtro'}
        </button>
        {allowClear ? (
          <button className={st.btnClear} onClick={handleClear}>
            Limpar
          </button>
        ) : null}
      </form>
      <button className="btn" onClick={() => setOpenFilterMobile(true)}>
        Filtrar
      </button>
    </>
  )
}

export default Filter
