import React, { useEffect, useState } from 'react'
import { Table } from '~/components'
import { useContext } from '~/hooks'
import { useApi } from '~/siteApi'

const Invoices = () => {
  const { token, setLoading } = useContext()

  const [page, setPage] = useState(1)
  const [max, setMax] = useState(10)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const {
    pageTotal: pagesNum,
    records,
    messageServer,
    registerTotal: allRecords,
  } = useApi(
    { records: [], pageTotal: 0, messageServer: '', registerTotal: 0 },
    `invoices?token=${token}&max=${max}&page=${page}${
      initialDate ? '&initialDate=' + initialDate : ''
    }${finalDate ? '&finalDate=' + finalDate : ''}`
  )
  const [numberInvoice, setNumberInvoice] = useState(0)
  const [numberBillet, setNumberBillet] = useState(0)
  const [serieInvoice, setSerieInvoice] = useState('')

  const data = (records || []).map((record) => {
    // const [day, moth, year] = record.dateIssuance.split('/')
    // const yearFormat = +year < 90 ? '20' + year : '19' + year
    // const formatDateIssuance = day + '/' + moth + '/' + yearFormat

    return {
      dateIssuance: record.dateIssuance,
      numberNF: record.numberNF || '',
      serie: record.serie,
      value: record.totalValue.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      tax: record.tax.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      link: record.codeVerify ? record.link : 'https',
      details: record.itens.map((obj) => {
        const percentage = (obj.tax * 100) / obj.value
        return {
          project: obj.itemDescription,
          item: obj.item,
          price: obj.valueUnit.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          quantity: obj.quantity,
          total: obj.value.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          tax: obj.tax.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          percentage: Math.floor(percentage) + '%',
        }
      }),
    }
  })

  useEffect(() => {
    setLoading(records)
  }, [records])

  return (
    <section>
      <h1>Notas fiscais</h1>
      <Table
        dataHead={['Emissão', 'Documento', 'Série', 'Valor', 'Imposto']}
        dataCells={data}
        hasDetails={true}
        max={max}
        page={page}
        pagesNum={pagesNum}
        setPage={setPage}
        setMax={setMax}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setNumberInvoice={setNumberInvoice}
        numberInvoice={numberInvoice}
        setNumberBillet={setNumberBillet}
        numberBillet={numberBillet}
        setSerieInvoice={setSerieInvoice}
        serieInvoice={serieInvoice}
        total={allRecords}
        message={messageServer}
        dataNumberBillet={records}
      />
    </section>
  )
}

export default Invoices
