// Aqui os dados do head das páginas

import React from 'react'
import Helmet from 'react-helmet'

function canonicalUrl({ pathname }: Location) {
  return `https://${process.env.GATSBY_SITE_URL}${
    pathname === '/' ? '' : pathname
  }`
}

interface Props {
  title?: string
  description?: string
  location: Location
}

const DEFAULT_TITLE = process.env.GATSBY_SITE_NAME
const DEFAULT_DESCRIPTION = ''

const Head = ({ title, description, location }: Props) => (
  <Helmet>
    <meta
      property="og:image"
      itemProp="image"
      content={`https://${process.env.GATSBY_SITE_URL}/opengraph.jpg`}
    />
    <meta
      name="twitter:image"
      content={`https://${process.env.GATSBY_SITE_URL}/opengraph.jpg`}
    />
    <title>{title || DEFAULT_TITLE}</title>
    <meta property="og:title" content={title || DEFAULT_TITLE} />
    <meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} />
    <meta name="description" content={description || DEFAULT_DESCRIPTION} />
    <meta
      property="og:description"
      content={description || DEFAULT_DESCRIPTION}
    />
    <link rel="canonical" href={canonicalUrl(location)} />
    <meta property="og:url" content={canonicalUrl(location)} />
    <link
      rel="shortcut icon"
      href="https://www.seventh.com.br/favicon-32x32.png"
      type="image/png"
    />
    <meta property="og:locale" content="pt_BR" />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
  </Helmet>
)

export default Head
