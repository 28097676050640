import React from 'react'
import * as stTable from '~/assets/styl/Table.module.styl'
import * as st from '~/assets/styl/TableMobile.module.styl'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { ChangePassword } from '~/components'
import { useContext } from '~/hooks'

const MyData = () => {
  const breakpoints = useBreakpoint()
  const { profile } = useContext()

  return (
    <section>
      <h1>Meus dados</h1>
      {!breakpoints.sm ? (
        <table className={stTable.core}>
          <tr>
            <th>Nome fantasia</th>
            <th>Razão social</th>
            <th>CNPJ</th>
            <th>E-mail</th>
          </tr>
          <tr className={stTable.customPadding}>
            <td>{profile?.company}</td>
            <td>{profile?.name}</td>
            <td>
              {profile?.document.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5'
              )}
            </td>
            <td>{profile?.emails}</td>
          </tr>
        </table>
      ) : (
        <ul className={st.core}>
          <li>
            <p>
              <strong>Nome fantasia</strong>
            </p>
            <p>{profile?.company}</p>
          </li>
          <li>
            <p>
              <strong>Razão social</strong>
            </p>
            <p>{profile?.name}</p>
          </li>
          <li>
            <p>
              <strong>CNPJ</strong>
            </p>
            <p>{profile?.document}</p>
          </li>
          <li>
            <p>
              <strong>E-mail</strong>
            </p>
            <p>{profile?.emails}</p>
          </li>
        </ul>
      )}
      <section>
        <h2>Segurança</h2>
        <ChangePassword />
      </section>
    </section>
  )
}

export default MyData
