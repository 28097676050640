import React from 'react'
import ReactPaginate from 'react-js-pagination'
import * as st from '~/assets/styl/Pagination.module.styl'

interface Props {
  page: number
  pageCount: number
  itemsPerPage: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const Pagination = ({ page, pageCount, itemsPerPage, setPage }: Props) => {
  return pageCount > 1 ? (
    <ReactPaginate
      itemsCountPerPage={itemsPerPage}
      totalItemsCount={itemsPerPage * pageCount}
      activePage={page}
      pageRangeDisplayed={5}
      prevPageText="Anterior"
      nextPageText="Próxima"
      onChange={(page) => setPage(page)}
      innerClass={st.core}
    />
  ) : (
    <></>
  )
}

export default Pagination
