exports.components = {
  "component---src-templates-changed-password-tsx": () => import("./../../../src/templates/ChangedPassword.tsx" /* webpackChunkName: "component---src-templates-changed-password-tsx" */),
  "component---src-templates-create-password-tsx": () => import("./../../../src/templates/CreatePassword.tsx" /* webpackChunkName: "component---src-templates-create-password-tsx" */),
  "component---src-templates-dashboard-tsx": () => import("./../../../src/templates/Dashboard.tsx" /* webpackChunkName: "component---src-templates-dashboard-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/NotFound.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-password-required-tsx": () => import("./../../../src/templates/PasswordRequired.tsx" /* webpackChunkName: "component---src-templates-password-required-tsx" */),
  "component---src-templates-password-sent-tsx": () => import("./../../../src/templates/PasswordSent.tsx" /* webpackChunkName: "component---src-templates-password-sent-tsx" */)
}

