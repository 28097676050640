import React, { useState, useEffect } from 'react'
import { Link, Menu, Invoices, Slips, MyData } from '~/components'
import * as st from '~/assets/styl/Content.module.styl'
import logoWhite from '~/assets/svg/logo-white.svg'
import { useContext } from '~/hooks'
import cn from 'classnames'

const Content = ({ location }: { location: Location }) => {
  // Atualizar ~/data/pages.js também
  const menuItems = [
    {
      title: 'Dashboard',
      component: <Menu />,
      url: '/dashboard/',
    },
    {
      title: 'Notas fiscais',
      component: <Invoices />,
      url: '/notas-fiscais/',
    },
    {
      title: 'Boletos',
      component: <Slips />,
      url: '/boletos/',
    },
    {
      title: 'Meus dados',
      component: <MyData />,
      url: '/meus-dados/',
    },
  ]

  const { logout, profile, token } = useContext()
  const loggedIn = useState(Boolean(token && profile))[0]

  const [component, _setComponent] = useState<JSX.Element>(
    (loggedIn &&
      menuItems.find(
        ({ url }) =>
          url.replace(/\//g, '') === location.pathname.replace(/\//g, '')
      )?.component) || <Menu />
  )
  const setComponent = (component: JSX.Element) => {
    const menuItem = menuItems.find(
      ({ component: menuComponent }) =>
        menuComponent.type.name === component?.type.name
    )

    if (menuItem) {
      history.pushState({}, '', menuItem.url)
      _setComponent(menuItem.component)
    }
  }
  const active = component.type.name

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).componentHandler = setComponent
  }, [setComponent])

  const [openNavMobile, setOpenNavMobile] = useState(false)

  return loggedIn ? (
    <>
      <header className={cn(st.core, openNavMobile && st.active)}>
        <img src={logoWhite} alt="Logo da Seventh" />
        <button onClick={() => setOpenNavMobile(true)}>
          Abrir Menu<span></span>
        </button>
        <nav className={openNavMobile && st.active}>
          <button onClick={() => setOpenNavMobile(false)}>
            Fechar menu<span></span>
          </button>
          <p>
            Voce está logado como <strong>{profile?.company}</strong>
          </p>
          <ul>
            {menuItems.map(({ title, component }, index) => (
              <li
                key={index}
                className={active === component.type.name && st.active}
              >
                <Link
                  href=""
                  onClick={(e) => {
                    e.preventDefault()
                    setComponent(component)
                    setOpenNavMobile(false)
                  }}
                >
                  {title}
                </Link>
              </li>
            ))}
            <li>
              <button onClick={logout}>Sair</button>
            </li>
          </ul>
        </nav>
        <small>
          <a href="https://www.tiki.com.br/">Powered by tiki</a>
        </small>
      </header>
      <main className="bkg-main container">
        <div className="content-desk">
          <p>
            Voce está logado como <strong>{profile?.company}</strong>
          </p>{' '}
          <button onClick={logout}>Sair</button>
        </div>
        <div className="container-components">{component}</div>
        <small className="content-mobile tiki-footer">
          <a href="https://www.tiki.com.br/">Powered by tiki</a>
        </small>
      </main>
    </>
  ) : (
    <></>
  )
}

export default Content
