import React from 'react'
import * as st from '~/assets/styl/ModalSlips.module.styl'
import * as stTable from '~/assets/styl/Table.module.styl'
import * as stTableMobile from '~/assets/styl/TableMobile.module.styl'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import cn from 'classnames'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'

const ModalSlips = ({
  setOpenModal,
  numberInvoice,
  serieInvoice,
  numberBillet,
}) => {
  const breakpoints = useBreakpoint()
  const { token } = useContext()
  const { records } = useApi(
    { records: [] },
    `billets?token=${token}&numberBillet=${numberBillet}`
  )
  const slips = records?.map((item) => {
    return {
      number: item.number,
      installment: item?.installment,
      serie: item.serie,
      dateDue: item.dateDue,
      value: item.value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      balance: item.balance.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      status: item.status,
      dateIssuance: item.dateIssuance,
      link: `${process.env.API_URL}/billets/${item.number}?token=${token}&installment=${item.installment}`,
    }
  })

  return (
    <div className={st.core}>
      <section>
        <h1>
          Título: {numberInvoice}/{serieInvoice}
        </h1>
        <button onClick={() => setOpenModal(false)}>
          <span>Fechar</span>
        </button>
        {!breakpoints.sm ? (
          <table className={cn(stTable.core, stTable.customPadding)}>
            <tr>
              <th>Parcela</th>
              <th>Vencimento</th>
              <th>Valor</th>
              <th colSpan={2}>Saldo</th>
            </tr>
            {slips && slips.length > 0 ? (
              slips?.map((slip, index) => (
                <tr key={index} className={stTable.customPadding}>
                  <td>{slip?.installment}</td>
                  <td>{slip.dateDue}</td>
                  <td>{slip.value}</td>
                  <td>{slip.balance}</td>
                  <td>
                    {slip.status != 'Baixado' && (
                      <a href={slip.link} target="_blank" rel="noreferrer"></a>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className={st.emptyTd}>
                  Nenhum título encontrado
                </td>
              </tr>
            )}
          </table>
        ) : (
          (!slips && <p>Não há boletos</p>) ||
          slips?.map((slip, index) => (
            <>
              <ul
                key={index}
                className={cn(stTableMobile.core, stTableMobile.customMargin)}
              >
                <li>
                  <p>
                    <strong>Parcela</strong>
                  </p>
                  <p>{slip?.split}</p>
                </li>
                <li>
                  <p>
                    <strong>Vencimento</strong>
                  </p>
                  <p>{slip.dateIssuance}</p>
                </li>
                <li>
                  <p>
                    <strong>Valor</strong>
                  </p>
                  <p>{slip.value}</p>
                </li>
                <li>
                  <p>
                    <strong>Saldo</strong>
                  </p>
                  <p>{slip.balanace}</p>
                </li>
              </ul>
              {slip.status != 'Baixado' && (
                <a href={slip.link} key={index} className="btn blue">
                  <span>Imprimir</span>
                </a>
              )}
            </>
          ))
        )}
      </section>
    </div>
  )
}

export default ModalSlips
