import React from 'react'
import * as st from '~/assets/styl/ChangePassword.module.styl'
import { Form } from '~/components'

const ChangePassword = () => {
  return (
    <div className={st.core}>
      <p>Altere aqui sua senha</p>
      <Form
        inputs={[
          {
            label: 'Digite a nova senha',
            name: 'password',
            type: 'password',
          },
          {
            label: 'Repita a nova senha',
            name: 'repassword',
            type: 'password',
          },
          {
            label: 'Digite sua senha atual',
            name: 'mypassword',
            type: 'password',
          },
        ]}
        idPrefix="1"
        path={'profile/change-password'}
        location={location}
        button={<button className="btn blue">Salvar</button>}
      />
    </div>
  )
}

export default ChangePassword
