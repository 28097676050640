import React, { ReactNode, useState, useEffect } from 'react'
import { Form, Link } from '~/components'
import * as st from '~/assets/styl/Login.module.styl'
import logo from '~/assets/svg/logo.svg'

interface Props {
  title?: string
  input?: Array<{
    name: string
    label: ReactNode
    placeholder?: string
    readonly?: string
    value?: string
    obs?: string
    type?: string
    mask?: 'phone' | 'date' | 'int' | 'currency' | 'cnpj' | 'cpf'
    required?: boolean
    className?: string
    options?: Array<{
      name: string
      value?: string
    }>
    file?: {
      accept: string
      button: (props: { onClick: React.MouseEventHandler }) => ReactNode
    }
  }>
  btnRequest?: string
  btnLoading?: string
  path?: string
  nextPage?: string
  location: Location
}

const Login = ({
  title,
  input,
  btnRequest,
  btnLoading,
  path,
  nextPage,
  location,
}: Props) => {
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])

  return (
    <div className={st.core}>
      <img src={logo} alt="Logo da Seventh" />
      <section>
        <h1>{title}</h1>

        <Form
          idPrefix="example"
          button={<button>{btnRequest}</button>}
          loadingButton={<button>{btnLoading}</button>}
          inputs={input || []}
          path={path}
          nextPage={nextPage}
          location={location}
        />

        {pathname === '/' && (
          <Link className="btn white" href="/solicitar-senha">
            Solicitar acesso
          </Link>
        )}
        {pathname !== '/' && (
          <Link className="gray-underline" href="/">
            Voltar
          </Link>
        )}
      </section>
      <small>
        <a href="https://www.tiki.com.br/" target="_blank" rel="noreferrer">
          Powered by tiki
        </a>
      </small>
    </div>
  )
}

export default Login
