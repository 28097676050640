import React, { useEffect, useState } from 'react'
import * as stTotal from '~/assets/styl/Total.module.styl'
import { Table } from '~/components'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'

const Slips = () => {
  const { token, setLoading } = useContext()

  const [page, setPage] = useState(1)
  const [max, setMax] = useState(10)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [isOpen, setIsOpen] = useState(1)
  const data = useApi(
    { records: [], pageTotal: 0, messageServer: '', registerTotal: 0 },
    `billets?token=${token}&max=${max}&page=${page}${
      initialDate ? '&initialDate=' + initialDate : ''
    }${finalDate ? '&finalDate=' + finalDate : ''}${
      isOpen ? '&status=' + isOpen : ''
    }`
  )
  const totalValue = (data.records || []).reduce(
    (acc, curr) => acc + curr.value,
    0
  )
  const totalValueOpen = (data.records || []).reduce(
    (acc, curr) => acc + curr.balance,
    0
  )
  const tableData = (data.records || []).map((item) => {
    // const [day, moth, year] = item.dateIssuance.split('/')
    // const [dayDue, mothDue, yearDue] = item.dateDue.split('/')
    // const yearFormat = +year < 90 ? '20' + year : '19' + year
    // const yearFormatDue = +yearDue < 90 ? '20' + yearDue : '19' + yearDue
    // const formatDateIssuance = day + '/' + moth + '/' + yearFormat
    // const formatDateDue = dayDue + '/' + mothDue + '/' + yearFormatDue

    return {
      status: item.status,
      dateIssuance: item.dateIssuance,
      serie: item.serie,
      number: item.number,
      installment: item.installment,
      dateDue: item.dateDue,
      value: item.value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      balance: item.balance.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      fileData: item.fileData,
      link: `${process.env.API_URL}/billets/${item.number}?token=${token}&installment=${item.installment}`,
    }
  })

  useEffect(() => {
    setLoading(data)
  }, [data])

  return (
    <section>
      <h1>Boletos</h1>
      <ul className={stTotal.core}>
        <li>
          <p>Total em títulos</p>
          <p>
            {(totalValue || 0).toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            })}
          </p>
        </li>
        <li>
          <p>Total em títulos abertos</p>
          <p>
            {totalValueOpen.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            })}
          </p>
        </li>
      </ul>
      <Table
        dataHead={[
          'Status',
          'Emissão',
          'Prefixo',
          'Número',
          'Parcela',
          'Vencimento',
          'Valor',
          'Em aberto',
        ]}
        dataCells={tableData}
        max={max}
        page={page}
        pagesNum={data.pageTotal}
        setPage={setPage}
        total={data.registerTotal}
        setMax={setMax}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setIsOpen={setIsOpen}
        isOpen={['Aberto', 'Baixado']}
        message={data?.messageServer}
      />
    </section>
  )
}

export default Slips
