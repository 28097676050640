import React from 'react'
import { Invoices, Slips } from '~/components'
import * as st from '~/assets/styl/Menu.module.styl'
import bkgNotas from '~/assets/img/img-notas-fiscais.png'
import bkgBoletos from '~/assets/img/img-boletos.png'

const Menu = () => {
  const menuItems = [
    {
      background: bkgNotas,
      component: <Invoices />,
      title: 'Notas fiscais',
      url: '/notas-fiscais/',
    },
    {
      background: bkgBoletos,
      component: <Slips />,
      title: 'Boletos',
      url: '/boletos/',
    },
  ]

  return (
    <section className={st.core}>
      <h1>Acesso ao cliente</h1>
      <nav>
        <ul>
          {menuItems.map(({ background, component, title, url }, index) => (
            <li
              key={index}
              style={{
                background: `url(${background}) center center no-repeat`,
              }}
            >
              <a
                href={url}
                onClick={(e) => {
                  e.preventDefault()
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ;(window as any).componentHandler?.(component)
                }}
              >
                <span>{title}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  )
}

export default Menu
